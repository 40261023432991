
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import AddTagsModal from "@/components/modals/forms/AddTagsModal.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { useRouter } from "vue-router";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {
    AddTagsModal,
  },
  //   methods: {
  //     onInput() {
  //     if (this.formData.brand_select.length > 1) {
  //       this.setBranddata("")
  //       console.log("Input")
  //     }
  //   }
  // },

  setup(props, { emit }) {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const metal_data = ref<Array<Category>>([]);
    const category_data = ref<Array<Category>>([]);
    const sub_category_data = ref<Array<Category>>([]);
    const store = useStore();
    const table_cols_data = ref<any>([]);
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const dialogAttrTableVisible = ref(false);
    const dialogStandardAttrTableVisible = ref(false);

    const brand_data = ref([]);
    const uom_data = ref([]);
    const standard_data = ref([]);
    const grade_data = ref([]);
    const branch = ref([]);
    const multipleSelection = ref<any>([]);
    const multipleSelection1 = ref();
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleTableRef1 = ref<InstanceType<typeof ElTable>>();
    const displayTableRef = ref<InstanceType<typeof ElTable>>();
    const displayTableRef1 = ref<InstanceType<typeof ElTable>>();
    const table_data = ref<any>([]);
    const hsn_data = ref<any>([]);
    const manufacturer_data = ref<any>([]);
    const brands_data = ref<any>([]);

    const sa_table_data = ref<any>([]);
    const sa_table_cols_data = ref<any>([]);

    const categoryTree = ref([]);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });
    const newTreeNode = ref<any>({});
    const newTreeValue = ref<any>([]);
    const treeRef = ref<null | HTMLFormElement>(null);

    const testJson = {
      company_id: 15,
      plant_id: 1,
      brand_id: 1,
      standard_id: 1,
      standard_attribute_id: 1,
      category_attributes: [
        { category_attribute_setting_id: 2, category_attribute_value: "2" },
        { category_attribute_setting_id: 3, category_attribute_value: "1300" },
        { category_attribute_setting_id: 4, category_attribute_value: 3 },
      ],
      hsn_code: 29280090,
      user_id: 1,
      active: 1,
    };

    const company = ref([]);

    interface Category {
      category_id: string;
      category_name: string;
      is_next_child_present: number;
    }
    // const OnClickCompany = async (compid) => {
    //   try {
    //         var values = { "company_id"  : compid, "page"  : 1, "records_per_page" : 10 }
    //         await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {
    //         branch.value = data.result_list;
    //         console.log(branch.value)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });

    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    const productdata = ref([]);
    const setProductDropdown = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_SELECTBOX, values)
          .then(({ data }) => {
            productdata.value = data;
            //loading.value = false;
            console.log(productdata.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const compseller = ref([]);
    const setCompanySellerdata = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = {
          company_id: 0,
          search_term: data,
          page: 1,
          records_per_page: 10,
        };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            compseller.value = data.result_list;
            //loading.value = false;
            console.log(compseller.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setCompanyData = async (data) => {
      debugger;
      const db_data = {};
      var user = JSON.parse(JwtService.getToken());
      //loading.value = true;
      if (multipleSelection.value.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Category Attribute.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      try {
        var values = {
          category_id: data.category_select,
          category_attribute_mapping_id: data.attr_select,
          standard_id: data.standard_select,
          standard_attribute_mapping_id: data.sa_attr_select,
          manufacturer_company_id: data.manufacturer_select,
          brand_id: data.brand_select,
          hsn_code: data.hsn_select,
          user_id: user.user_id,
          active: 1,
        };
        await axios
          .post("https://services.mymetal.in/common_products/create", values, {
            headers: {
              "x-api-key": "BvFPhsxvLB5cTrNLbWdGC2aayThFkC6Z4iPcXeDh",
            },
          })
          .then(({ data }) => {
            debugger;
            loading.value = false;
            console.log(data);
            if (data.statusCode === 200 || data.statusCode === 201) {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                  text: "Form has been successfully submitted!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  hideModal(addProductModalRef.value);
                  emit("refreshList");
                });
              }, 2000);
            } else {
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        console.log(e);
      }
    };

    //const shape = ref([]);

    // const setShapedata = async (data) => {
    //   const db_data = {}
    //     loading.value = true;
    //     try {
    //       await store.dispatch(Actions.CUST_GET_PRODUCT_SHAPE, db_data).then(({ body }) => {
    //       shape.value = body.shape_list;
    //       //loading.value = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });

    //   } catch (e) {
    //     console.log(e);
    //   }

    // }

    const parent = ref([]);
    const setParentData = async (data) => {
      //loading.value = true;
      try {
        //var values = {"search_term" : data}
        await store
          .dispatch(Actions.CUST_GET_PARENT_CATEGORY)
          .then(({ data }) => {
            parent.value = data;
            //loading.value = false;
            console.log("Parent");
            console.log(parent.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const making = ref([]);
    const setMakingdata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_MAKING, param)
          .then(({ body }) => {
            making.value = body.making_process_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const comp = ref([]);
    const setCompositiondata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_COMPOSITION, param)
          .then(({ body }) => {
            comp.value = body.composition_types_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const brand = ref([]);
    const setBranddata = async (data) => {
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_PRODUCT_BRAND, values)
          .then(({ data }) => {
            brand.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const formData = ref({
      standard_select: "",
      grade_select: "",
      metal_select: "",
      category_select: "",
      sub_category_select: "",
      manufacturer_select: "",
      //branch_select: "",
      brand_select: "",
      uom_select: "",
      hsn_code: "",
      hsn_select: "",
      attr_select: "",
      sa_attr_select: "",
    });

    const rules = ref({
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",
        },
      ],
      manufacturer_select: [
        {
          required: true,
          message: "Manufacturer is required",
          trigger: "change",
        },
      ],
      hsn_select: [
        {
          required: true,
          message: "HSN is required",
          trigger: "change",
        },
      ],
    });

    const setProductData = async (data) => {
      const db_data = {
        product_id: 0,
        company_id: data.company_select,
        parent_product_id: data.product_select,
        parent_company_id: data.sellercomp_select,
        company_trade_type_id: 1,
        //"branch_id" : data.branch_select,
        brand_id: data.brand_select,
        product_name: data.name,
        product_description: data.description,
        product_short_name: data.short_name,
        category_id: data.category_select,
        making_process_id: data.making_select,
        composition_id: data.composition_select,
        created_user_id: "1",
        // "created_ip": "0.0.0.0",
        // "created_uagent": "Test",
        active: data.active,
      };

      await store
        .dispatch(Actions.CUST_ADD_PRODUCT, db_data)
        .then(({ data }) => {
          if (data) {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "Product has been successfully created.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addProductModalRef.value);
                router.go(0);
              });
            }, 2000);
          } else {
            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    interface Tree {
      category_name: string;
      category_id: string;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      formData.value["standard_select"] = "";
      if (checked) {
        treeRef.value?.setCheckedKeys([data.category_id], true);
        newTreeValue.value = [data.category_id];
        formData.value["category_select"] = data.category_id;
        newTreeNode.value = data;
        getAttributeList(data.category_id);
        getStandardData(data.category_id);
      } else {
        if (data.category_id === newTreeValue.value[0]) {
          treeRef.value?.setCheckedKeys([], false);
          newTreeValue.value = [];
          formData.value["category_select"] = "";
          newTreeNode.value = data;
        } else {
          treeRef.value?.setCheckedKeys(newTreeValue.value, false);
          formData.value["category_select"] = newTreeValue.value[0]
            ? newTreeValue.value[0]
            : "";
          table_data.value = [];
          standard_data.value = [];
        }
      }
      console.log(data, checked, indeterminate);
    };
    // async function getCategoryData(id, type) {
    //   let params = {
    //     category_id: id,
    //   };

    //   await store
    //     .dispatch(Actions.CUST_GET_CATEGORY_NEW_LIST, params)
    //     .then(({ data }) => {
    //       debugger;
    //       if (id === 1) {
    //         metal_data.value = data ? data : [];
    //       } else if (type === "category") {
    //         category_data.value = data ? data : [];
    //       } else if (type === "subcat") {
    //         sub_category_data.value = data ? data : [];
    //         rules.value =
    //           sub_category_data.value.length <= 0
    //             ? {
    //                 metal_select: [
    //                   {
    //                     required: true,
    //                     message: "Metal type is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 category_select: [
    //                   {
    //                     required: true,
    //                     message: "Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 sub_category_select: [
    //                   {
    //                     required: false,
    //                     message: "Sub Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],

    //                 uom_select: [
    //                   {
    //                     required: true,
    //                     message: "UOM is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //               }
    //             : {
    //                 metal_select: [
    //                   {
    //                     required: true,
    //                     message: "Metal type is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 category_select: [
    //                   {
    //                     required: true,
    //                     message: "Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 sub_category_select: [
    //                   {
    //                     required: true,
    //                     message: "Sub Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],

    //                 uom_select: [
    //                   {
    //                     required: true,
    //                     message: "UOM is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //               };
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // }

    function onCategoryChange(type) {
      debugger;
      let category =
        type === "subcat"
          ? category_data.value.filter((cat) => {
              if (
                cat.category_id === formData.value["category_select"] &&
                type === "subcat"
              )
                return true;
              else if (
                cat.category_id === formData.value["sub_category_select"] &&
                type === "subsubcat"
              )
                return true;
              else return false;
              // return cat.category_id === formProductData.value["category_select"];
            })
          : sub_category_data.value.filter((cat) => {
              if (
                cat.category_id === formData.value["category_select"] &&
                type === "subcat"
                // (type === "subcat"
                //   ? formData.value["category_select"]
                //   : formData.value["sub_category_select"])
              )
                return true;
              else if (
                cat.category_id === formData.value["sub_category_select"] &&
                type === "subsubcat"
              )
                return true;
              else return false;
              // return cat.category_id === formProductData.value["category_select"];
            });
      let cat_id = category.length > 0 ? category[0]?.category_id : "";
      console.log("rules:" + JSON.stringify(rules.value));

      if (category[0]?.is_next_child_present === 1 && type != "subsubcat") {
        // getCategoryData(cat_id, type);
      } else if (
        category[0]?.is_next_child_present === 0 &&
        type != "subsubcat"
      ) {
        sub_category_data.value = [];
        if (formData.value["sub_category_select"] != "")
          formData.value["sub_category_select"] = "";
      } else if (cat_id && category[0]?.is_next_child_present === 0) {
        getBrandtData(cat_id);
        getAttributeList(cat_id);
        getStandardData(cat_id);
      } else if (type != "subsubcat") {
        // getCategoryData(cat_id, type);
      }

      // rules.value =
      //   sub_category_data.value.length <= 0
      //     ? {
      //         metal_select: [
      //           {
      //             required: true,
      //             message: "Metal type is required",
      //             trigger: "change",
      //           },
      //         ],
      //         category_select: [
      //           {
      //             required: true,
      //             message: "Category is required",
      //             trigger: "change",
      //           },
      //         ],
      //         sub_category_select: [
      //           {
      //             required: false,
      //             message: "Sub Category is required",
      //             trigger: "change",
      //           },
      //         ],

      //         uom_select: [
      //           {
      //             required: true,
      //             message: "UOM is required",
      //             trigger: "change",
      //           },
      //         ],
      //       }
      //     : {
      //         metal_select: [
      //           {
      //             required: true,
      //             message: "Metal type is required",
      //             trigger: "change",
      //           },
      //         ],
      //         category_select: [
      //           {
      //             required: true,
      //             message: "Category is required",
      //             trigger: "change",
      //           },
      //         ],
      //         sub_category_select: [
      //           {
      //             required: true,
      //             message: "Sub Category is required",
      //             trigger: "change",
      //           },
      //         ],

      //         uom_select: [
      //           {
      //             required: true,
      //             message: "UOM is required",
      //             trigger: "change",
      //           },
      //         ],
      //       };
    }

    async function getBrandtData(id) {
      let params = {
        category_id: id,
      };

      await store
        .dispatch(Actions.CUST_GET_BRAND_BY_CATEGORY_LIST, params)
        .then(({ data }) => {
          brand_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getStandardData(id) {
      let params = {
        category_id: id,
        // category_standard_id: 0,
        // page: 1,
        // records_per_page: 100,
        // search_term: "",
      };

      await store
        .dispatch(Actions.CUST_GET_STANDARD_LIST_BY_CASTEGORY_DATA, params)
        .then(({ data }) => {
          debugger;
          standard_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getGradeData() {
      let params = {
        standard_attribute_id: 0,
        standard_id: formData.value["standard_select"],
        page: 1,
        records_per_page: 100,
        search_term: "",
      };

      await store
        .dispatch(Actions.CUST_GET_GRADE_LIST_DATA, params)
        .then(({ data }) => {
          debugger;
          grade_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAttributeList(id) {
      let params = {
        category_id: id,
      };

      await store
        .dispatch(Actions.CUST_GET_ATTRIBUTE_LIST_BY_CATEGORY_ID, params)
        .then(({ data }) => {
          debugger;
          table_cols_data.value = [];
          table_data.value = [];
          data = data.result_list;
          data[0].category_attribute_values_nested_json.map((item) => {
            if (item.sub_attributes.length > 0) {
              table_cols_data.value.push(item.category_attribute_label);
              item.sub_attributes.map((col) => {
                table_cols_data.value.push(col.category_attribute_label);
              });
            } else {
              table_cols_data.value.push(item.category_attribute_label);
            }
          });
          // let table_rows: Record<string, string | number>[];
          // let table_rows = Record<string, string | number>[];
          data.map((item) => {
            let value = {};
            value["category_attribute_mapping_id"] =
              item.category_attribute_mapping_id;
            item.category_attribute_values_nested_json.map((col) => {
              if (col.sub_attributes.length > 0) {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
                col.sub_attributes.map((attr) => {
                  value[attr.category_attribute_label] =
                    attr.category_attribute_value;
                });
              } else {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
              }
            });
            table_data.value.push(value);
          });
          // table_data = table_rows;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getStandardAttributeList() {
      let params = {
        standard_id: formData.value["standard_select"],
        // page: parseInt(paginationData.value["activePage"]),
        // records_per_page: parseInt(paginationData.value["perPage"]),
      };

      await store
        .dispatch(Actions.CUST_STANDARD_ATTRIBUTE_LIST_BY_STANDARD_ID, params)
        .then(({ data }) => {
          debugger;
          sa_table_cols_data.value = [];
          sa_table_data.value = [];
          data = data.result_list;
          data[0]?.standard_attribute_values_nested_json.map((item) => {
            if (item.sub_attributes.length > 0) {
              sa_table_cols_data.value.push(item.standard_attribute_label);
              item.sub_attributes.map((col) => {
                sa_table_cols_data.value.push(col.standard_attribute_label);
              });
            } else {
              sa_table_cols_data.value.push(item.standard_attribute_label);
            }
          });
          // let table_rows: Record<string, string | number>[];
          // let table_rows = Record<string, string | number>[];
          data.map((item) => {
            let value = {};
            value["standard_attribute_mapping_id"] =
              item.standard_attribute_mapping_id;
            item.standard_attribute_values_nested_json.map((col) => {
              if (col.sub_attributes.length > 0) {
                value[col.standard_attribute_label] =
                  col.standard_attribute_value;
                col.sub_attributes.map((attr) => {
                  value[attr.standard_attribute_label] =
                    attr.standard_attribute_value;
                });
              } else {
                value[col.standard_attribute_label] =
                  col.standard_attribute_value;
              }
            });
            sa_table_data.value.push(value);
          });
          // table_data = table_rows;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const handleSelectionChange = (val: any[]) => {
      debugger;
      let index = val.length != 0 ? val.length - 1 : 0;
      // multipleTableRef.value!.setCurrentRow(val);
      multipleSelection.value = [];
      multipleSelection.value[0] = val[index];
      val.forEach((row) => {
        if (row === val[index]) {
          multipleTableRef.value!.toggleRowSelection(row, true);
        } else {
          multipleTableRef.value!.toggleRowSelection(row, false);
        }
      });
    };

    const handleSaSelectionChange = (val: any[]) => {
      debugger;
      let index = val.length != 0 ? val.length - 1 : 0;
      // multipleTableRef1.value!.setCurrentRow(val);
      multipleSelection1.value = [];
      multipleSelection1.value[0] = val[index];
      val.forEach((row) => {
        if (row === val[index]) {
          multipleTableRef1.value!.toggleRowSelection(row, true);
        } else {
          multipleTableRef1.value!.toggleRowSelection(row, false);
        }
      });
    };

    const getManufacturerData = async (data) => {
      debugger;
      const db_data = {
        search_term: "0",
      };
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn_data.value = data;
            console.log("HSN VALUE");
            console.log(hsn_data.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    async function getCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }

      // let values = { trade_type: 1 };
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getBrands() {
      // let values = { trade_type: 1 };
      let values = { company_id: formData.value["manufacturer_select"] };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brands_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const setHsnData = async (query: string) => {
      debugger;
      const db_data = {
        search_term: "0",
      };
      loading.value = true;
      try {
        var values = { search_term: query };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn_data.value = data;
            console.log("HSN VALUE");
            console.log(hsn_data.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Attribute",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["attr_select"] =
          multipleSelection.value[0].category_attribute_mapping_id;
        dialogAttrTableVisible.value = false;
      }
    };
    const onSaContinue = () => {
      debugger;
      if (multipleSelection1.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Attribute",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["sa_attr_select"] =
          multipleSelection1.value[0].standard_attribute_mapping_id;
        dialogStandardAttrTableVisible.value = false;
      }
    };

    onMounted(async () => {
      await setParentData("");
      await setBranddata("");
      await setProductDropdown("");
      await setMakingdata(making.value);
      await setCompanySellerdata(compseller.value);
      await setCompositiondata(comp.value);
      // await getCategoryData(1, "");
      await getUOM();
      await getCategoryTree();
      //await OnClickCompany(branch.value)
    });
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addProductModalRef,
      setParentData,
      setMakingdata,
      setCompositiondata,
      setCompanyData,
      setBranddata,
      //OnClickCompany,
      setCompanySellerdata,
      setProductDropdown,
      active_list_data,
      productdata,
      compseller,
      company,
      branch,
      brand,
      comp,
      making,
      parent,
      metal_data,
      category_data,
      sub_category_data,
      // getCategoryData,
      // onMetalChange,
      onCategoryChange,
      getBrandtData,
      brand_data,
      getStandardData,
      getAttributeList,
      standard_data,
      dialogAttrTableVisible,
      dialogStandardAttrTableVisible,
      table_cols_data,
      table_data,
      multipleSelection,
      multipleSelection1,
      multipleTableRef,
      multipleTableRef1,
      handleSelectionChange,
      handleSaSelectionChange,
      onContinue,
      grade_data,
      getGradeData,
      getUOM,
      uom_data,
      displayTableRef,
      displayTableRef1,
      categoryTree,
      treeProps,
      treeRef,
      newTreeValue,
      newTreeNode,
      handleCheckChange,
      hsn_data,
      setHsnData,
      manufacturer_data,
      getCompanyList,
      getBrands,
      brands_data,
      getStandardAttributeList,
      sa_table_data,
      sa_table_cols_data,
      onSaContinue,
    };
  },
});
